import React, {useRef, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CustomTable({ 
    columns,
    data, 
    rowKey, 
    tableMinWidth, 
    tableContainerStyle={},
    tableOrder=null, 
    setTableOrder=null,
    highlightRowIds=[] 
}) {
    const rowRefs = useRef({});
    useEffect(() => {
        if (highlightRowIds.length > 0) {
            const firstHighlightId = highlightRowIds[0];
            const rowElement = rowRefs.current[firstHighlightId];
            if (rowElement) {
                rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [highlightRowIds]);
    const handleSort = (columnId) => {
        if (tableOrder?.orderBy === columnId) {
            // Toggle sort mode if the same column is clicked
            setTableOrder({
                orderBy: columnId,
                mode: tableOrder.mode === 'ASC' ? 'DESC' : 'ASC'
            });
        } else {
            // Set initial sort mode to DESC
            setTableOrder({
                orderBy: columnId,
                mode: 'DESC'
            });
        }
    };
    return (
        <TableContainer component={Paper} style={tableContainerStyle}>
            <Table sx={{ minWidth: tableMinWidth }} stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell 
                        key={column.id} 
                        align="center"
                        sx={{
                            cursor: column?.orderBy ? 'pointer' : 'default',
                            backgroundColor: column?.orderBy ? 'inherit' : 'white',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                backgroundColor: column?.orderBy ? 'rgba(0, 0, 0, 0.04)' : 'white'
                            }
                        }}
                        onClick={() => column?.orderBy && handleSort(column.id)}
                    >
                        {column?.orderBy ? (
                            <Tooltip title="Click to sort" placement="top">
                                <div style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    width: '100%' 
                                }}>
                                    <div style={{ width: '10px', display: 'flex', justifyContent: 'center' }} />
                                    <span style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>{column.label}</span>
                                    <div style={{ width: '10px', display: 'flex', justifyContent: 'center' }}>
                                        {tableOrder?.orderBy === column.id ? (
                                            tableOrder.mode === 'ASC' ? (
                                                <ArrowDropUpIcon fontSize="small" />
                                            ) : (
                                                <ArrowDropDownIcon fontSize="small" />
                                            )
                                        ) : null}
                                    </div>
                                </div>
                            </Tooltip>
                        ) : (
                            column.label
                        )}
                    </TableCell>
                ))}
            </TableRow>
            </TableHead>
                <TableBody>
                    {data.map((row, rowIndex) => (
                        <TableRow 
                            key={row[rowKey] || rowIndex}
                            ref={(el) => rowRefs.current[row[rowKey]] = el}
                            sx={{ 
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: highlightRowIds.includes(row["Id"]) ? 'rgba(255, 223, 186, 0.5)' : 'inherit'
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center">
                                    {column.render ? column.render(row, rowIndex) : row[column.id]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ TableContainer>
    )
}

export default CustomTable;