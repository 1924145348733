import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { Paper, Box} from '@mui/material';
import CustomTable from '../common/CustomTable';
import CommonButton from '../common/CommonButton';
import CustomTablePagination from '../common/CustomTablePagination';
import LabelFilter from './LabelFilter';
import { getTableData, getRowsByTime, findPageWithHighlightIds } from '../../utils/detectionTableUtils';

function DetectionsTable({
    data,
    labels,
    selectedLabel, setSelectedLabel,
    fpsValue,
    setImageLocation,
    setGalleryOpen, 
    setGalleryRow,
    videoTimestamp,
    selectedTab
}) {

    const [nRows, setNRows] = useState(10);  // Amount of rows to display. Default to 10 rows
    const [page, setPage] = useState(1);   // Page number. Default to 1.
    const [highlightRowIds, setHighlightRowIds] = useState([]);
    const {totalRows, tableData, pageIds} = getTableData(data, page, nRows, selectedLabel, fpsValue);
    const tableDataIds = tableData.map(row => row.Id);
    const allIdsExist = highlightRowIds.every(id => tableDataIds.includes(id));
    useEffect(() => {
        if ((page - 1) * nRows >= totalRows) {
            setPage(1);
        } else if (totalRows > 0 && tableData.length > 0) {
            setImageLocation(tableData[0].Location);
        }
    }, [data, page, nRows, selectedLabel])
    useEffect(() => {
        // if on Image Tab, highlight nothing
        if (selectedTab === 0 && !isEqual([], highlightRowIds)) {
            setHighlightRowIds([]);
        } else {
            const newHighlightRowIds = getRowsByTime(data, fpsValue, videoTimestamp);
            // Update state only if the value has changed
            if (!isEqual(newHighlightRowIds, highlightRowIds)) {
                setHighlightRowIds(newHighlightRowIds);
            }
        }
    }, [data, fpsValue, videoTimestamp]);
    useEffect(() => {
        if (!allIdsExist) {
            const newPage = findPageWithHighlightIds(highlightRowIds, pageIds);
            if (newPage !== null) {
                setPage(newPage);
            }
        }
    }, [highlightRowIds, data, fpsValue, nRows, selectedLabel])
    const detectionColumns = [
        { id: fpsValue ? 'FirstTimestamp' : 'FirstFrame', label: fpsValue ? <> First Appearance <br/> (mm:ss.SSS) </> : 'First Frame'},
        { id: fpsValue ? 'LastTimestamp' : 'LastFrame', label: fpsValue ? <> Last Appearance <br/> (mm:ss.SSS) </> : 'Last Frame'},
        { id: 'Label', label: 'Label'},
        { id: 'MedianScore', label: 'Median Score'},
        { id: 'AmountOfDetections', label: 'Amount of Detections'},
        { 
            id: 'Image', 
            label: 'Image', 
            render: (row) => (
                <CommonButton
                    onClick={() => setImageLocation(row.Location)}
                >
                    Show Image
                </CommonButton>
            )
        },
        { 
            id: 'All Images', 
            label: 'All Images', 
            render: (row) => (
                <CommonButton
                    onClick={() => {
                        setGalleryOpen(true);
                        setGalleryRow(row);
                    }}
                >
                    Gallery
                </CommonButton>
            )
        }
    ];
    return (
        <div style={{ width: '50%', marginRight: '10px'}}>
            <Paper>
                <Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <LabelFilter
                    labels={labels}
                    selectedLabel={selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                />
                <CustomTablePagination 
                    totalRows={totalRows}
                    rowsPerPage={nRows}
                    setRowsPerPage={setNRows}
                    page={page}
                    setPage={setPage}
                />
                </Box>
                <CustomTable
                    columns={detectionColumns}
                    data={tableData}
                    rowKey={"Id"}
                    tableMinWidth={300}
                    tableContainerStyle={{ maxHeight: 'calc(10 * 70px)', overflow: 'auto'}}
                    highlightRowIds={highlightRowIds}
                />
                <CustomTablePagination 
                    totalRows={totalRows}
                    rowsPerPage={nRows}
                    setRowsPerPage={setNRows}
                    page={page}
                    setPage={setPage}
                />
            </Paper>
        </div>
    )
}

export default DetectionsTable;