import React from 'react';
import { Tab, Tabs, Paper } from '@mui/material';
import ImageTab from './ImageTab';
import VideoTab from './VideoTab';

function MediaTabs ({
    imageLocation,
    setVideoTimestamp,
    selectedTab, setSelectedTab
}) {
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const textStyle = {color: '#616161'}

    return (
        <div style={{ width: '50%' }}> 
            <Paper 
                sx={{
                    height: '100%'
                }}
            >
                <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Image or Video tabs">
                    <Tab label="Image" />
                    <Tab label="Video" />
                </Tabs>
                {selectedTab === 0 && (
                    <ImageTab 
                        imageLocation={imageLocation}
                        textStyle={textStyle}
                    />
                )}
                {selectedTab === 1 && (
                    <VideoTab setVideoTimestamp={setVideoTimestamp}/>
                )}
                
            </Paper>
        </div>
    )
}

export default MediaTabs;