import { REQUEST_STATUS } from "../consts/requestStatus";

export const fetchVideoUrl = async (videoId) => {
    try {
        const params = new URLSearchParams({
            video_id: videoId
        }).toString();
        const requestUrl = process.env.REACT_APP_GET_VIDEO_URL;
        const response = await fetch(`${requestUrl}?${params}`, {
            method: 'GET',
        });
        if (!response.ok) {
            console.error('Failed to fetch video URL');
            return {
                videoUrl: '',
                requestStatus: REQUEST_STATUS.FAILURE,
            };
        }
        const data = await response.json();
        return {
            videoUrl: data.video_sas_url,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    } catch (error) {
        console.error('Failed to fetch video URL:', error);
        return {
            videoUrl: '',
            requestStatus: REQUEST_STATUS.FAILURE,
        };
    }
};