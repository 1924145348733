import React, {useContext} from 'react';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { StatusTableContext } from '../../contexts/StatusTableContext';
import { camelCaseToSpaces } from '../../utils/stringUtils';

function SearchTextField({ columnLabel }) {
    const { textSearch, setTextSearch, refreshTable } = useContext(StatusTableContext);
    const handleChange = (event) => {
        setTextSearch((prev) => ({
            ...prev,
            [columnLabel]: event.target.value
        }));
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (textSearch[columnLabel].trim()) {
                refreshTable();
            }
        }
    };
    return(
        <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <TextField
                variant="standard"
                label={camelCaseToSpaces(columnLabel)}
                value={textSearch[columnLabel]}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputProps={{ maxLength: 50 }}
            />
        </FormControl>
    )
}

export default SearchTextField;