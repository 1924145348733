import { Card, CardContent, Typography } from "@mui/material";

function InfoCard({ 
    maxWidth = 600, 
    text = 'Predictions were not generated for this inspection video.',
    transparent = false 
}) {
    return (
        <Card
            sx={{
                maxWidth: maxWidth,
                margin: '20px auto',
                padding: '16px',
                backgroundColor: transparent ? 'rgba(255, 255, 255, 0.5)' : 'white',
                borderColor: '#0288d1', // Blue border for info style
                borderWidth: 1,
                borderStyle: 'solid',
            }}
        >
            <CardContent>
                <Typography 
                    variant="h6" 
                    component="div" 
                    sx={{ color: '#0277bd', fontWeight: 'bold' }}
                >
                    Information
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {text}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default InfoCard;