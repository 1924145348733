import { useContext } from 'react';
import 'leaflet/dist/leaflet.css'
import { Popup } from 'react-leaflet'
import { MetadataContext } from '../../contexts/MetadataContext';
import { Typography, Box } from '@mui/material';
import { camelCaseToSpaces } from '../../utils/stringUtils';



function PipeInfoCard({sewerIdLabel="ID"}) {
    const { metadata, pipeInfo } = useContext(MetadataContext);
    if (pipeInfo) {
        const sewerId = metadata.find(item => item.FieldLabel === sewerIdLabel)?.FieldValue || null;
        const pipeInfoEntries = Object.entries(pipeInfo);
        return (
            <Popup>
            <Box>
                {sewerId && (
                    <Typography variant="subtitle2" sx={{ color: 'primary.main', fontWeight: 'bold'}}>
                        Sewer ID: {sewerId}
                    </Typography>
                )}
                <Typography variant="caption">
                    {pipeInfoEntries.map(([key, value]) => (
                        <>
                            <b>{camelCaseToSpaces(key)}</b>: {value ? value : 'N/A'} <br/>
                        </>
                    ))}
                </Typography>
                
            </Box>
            </Popup>

        );
    } else {
        return (
            <Popup>
            <Box>
                <Typography variant="caption">
                    An error occurred while fetching data. Please try again later or contact the support team.
                </Typography>
                
            </Box>
            </Popup>
        );
    }
}

export default PipeInfoCard;