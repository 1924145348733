import React from 'react';
import { useState } from 'react';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';
import InfoTabsLoading from './InfoTabsLoading';
import InfoTabsHeader from './InfoTabsHeader';
import TabsContentEvaluated from './TabsContentEvaluated';
import TabsContentNotEvaluated from './TabsContentNotEvaluated';

function InfoTabs ({
    videoName,
    videoInfo,
    labelsStat,
    monitoring,
    setSelectedLabel,
    videoWithPredictions,
    videoEvaluated,
    dataRequestStatus,
}) {

    const [selectedOverviewTab, setSelectedOverviewTab] = useState(0);
    const handleOverviewTabChange = (event, newValue) => {
        setSelectedOverviewTab(newValue);
    };
    const hasVideoPredictionsPath = !!videoWithPredictions;
    const textStyle = {color: '#616161'}
    if (dataRequestStatus === REQUEST_STATUS.LOADING && labelsStat === null) {
        return (
            <InfoTabsLoading/>
        )
    }
    if (dataRequestStatus === REQUEST_STATUS.FAILURE && labelsStat === null) {
        return (
            <DataErrorCard />
        )
    }
    return (
        <Paper
            sx={{ 
                mb: 2 , 
                px: 5, 
                pt: 3, 
                minHeight: '370px', 
                // maxHeight: '1000px'
            }}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: '0', 
                    zIndex: '1',
                    backgroundColor: 'white',
                }}
            >
                <InfoTabsHeader
                    textStyle={textStyle}
                    videoName={videoName}
                    hasVideoPredictionsPath={hasVideoPredictionsPath}
                    videoEvaluated={videoEvaluated}
                />
                {videoEvaluated ? (
                    <Tabs value={selectedOverviewTab} onChange={handleOverviewTabChange} aria-label="Info tabs">
                        <Tab label="Defects" />
                        <Tab label="Details" />
                        <Tab label="Metadata" />
                        <Tab label="Monitoring" />
                    </Tabs>
                ): (
                    <Tabs value={selectedOverviewTab} onChange={handleOverviewTabChange} aria-label="Info tabs">
                        <Tab label="Details" />
                        <Tab label="Monitoring" />
                        <Tab label="Metadata" />
                        <Tab label="Defects" />
                    </Tabs>
                )}
            </Box>
            <Box
                sx={{
                    overflowY: 'auto',
                    maxHeight: '80vh',
                    minHeight: '300px'
                }}
            >
            {videoEvaluated ? (
                <TabsContentEvaluated
                selectedOverviewTab={selectedOverviewTab}
                videoInfo={videoInfo}
                labelsStat={labelsStat}
                monitoring={monitoring}
                textStyle={textStyle}
                setSelectedLabel={setSelectedLabel}
            />
            ) : (
                <TabsContentNotEvaluated
                selectedOverviewTab={selectedOverviewTab}
                videoInfo={videoInfo}
                monitoring={monitoring}
                textStyle={textStyle}
                />
            )}
            </Box>
        </Paper>
    )
}

export default InfoTabs;