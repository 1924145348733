import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';

const validateName = (name) => {
    const isValid = /^[a-zA-Z0-9-_.]*$/.test(name);
    return isValid;
};

function NameChangeField({initialName, defaultName, onChangeName, videoNameError, setVideoNameError}) {
    const [errorText, setErrorText] = useState('');
    useEffect(() => {
        if (!validateName(defaultName)) {
            setVideoNameError(true);
            setErrorText('Only letters, numbers, hyphens (-), underscores (_), and periods (.) are allowed');
        } else {
            setVideoNameError(false);
            setErrorText('');
        }
    }, [defaultName, setVideoNameError])
    const handleNameChange = (e) => {
        const newValue = e.target.value;
        if (!validateName(newValue)) {
            setVideoNameError(true);
            setErrorText('Only letters, numbers, hyphens (-), underscores (_), and periods (.) are allowed');
        } else {
            setVideoNameError(false);
            setErrorText('');
        }
        onChangeName(newValue);
    };
    return (
        <>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Initial video name: {initialName}.</Typography>
            <Typography variant="body1" sx={{mt: 1, mb: 1}}>Video Name:</Typography>
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                <TextField
                    variant='outlined'
                    color='primary'
                    defaultValue={defaultName}
                    required
                    label="Required"
                    error={videoNameError || defaultName === ''}
                    helperText={videoNameError ? errorText : (defaultName === '' ? 'This field is required' : '')}
                    onChange={handleNameChange}
                    sx={{mt: '5px', mb: '5px'}}
                >
                </TextField>
                
            </Box>
        </>
    )
}

export default NameChangeField;