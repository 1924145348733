import React from 'react';
import { Typography } from '@mui/material';
import useRequestImage from '../../hooks/useRequestImage';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import ImageDisplay from './ImageDisplay';

function ImageTab({imageLocation, textStyle}) {
    const { imageData, requestStatus } = useRequestImage(imageLocation);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {requestStatus === REQUEST_STATUS.SUCCESS && (
                    <Typography align='center' variant="subtitle1" sx={{...textStyle, width: '100%'}}>
                        {imageLocation.split('/').pop()}
                    </Typography>
                )}
            </div>
            <ImageDisplay
                imageData={imageData}
                requestStatus={requestStatus}
            />
        </div>
    )
}

export default ImageTab;