import React, { useCallback} from 'react';
import { throttle } from 'lodash';

function VideoPlayer({ videoUrl, setVideoTimestamp }) {
  const handleTimeUpdate = useCallback(
    throttle((event) => {
      setVideoTimestamp(event.target.currentTime);
    }, 500),
    []
  );

  return (
    <div
      style={{
        width: 'calc(100% - 40px)',
        maxWidth: '800px',
        aspectRatio: '16 / 9',
        backgroundColor: '#000',
        position: 'relative',
        margin: '20px auto',
      }}
    >
      <video
        controls
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          objectFit: 'contain',
        }}
        onTimeUpdate={handleTimeUpdate}
        onError={(e) => console.error('Video Error:', e)}
      >
        <source src={videoUrl} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;