export const exampleMetadata = [
    {
        "FieldLabel": "StreetName",
        "FieldType": "text",
        "FieldValue": "Example street name"
    },
    {
        "FieldLabel": "InspectionDate",
        "FieldType": "date",
        "FieldValue": "2024-09-13"
    },
    {
        "FieldLabel": "InspectionDateTime",
        "FieldType": "datetime",
        "FieldValue": "2024-09-13T14:30"
    },
    {
        "FieldLabel": "ID",
        "FieldType": "number",
        "FieldValue": 1999
    },
    {
        "FieldLabel": "StartPointLocation",
        "FieldType": "text",
        "FieldValue": "123456.78 876543.21"
    },
    {
        "FieldLabel": "EndPointLocation",
        "FieldType": "text",
        "FieldValue": "876543.21 123456.78"
    },
    {
        "FieldLabel": "Material",
        "FieldType": "text",
        "FieldValue": "AG"
    }
];