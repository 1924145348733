const formatTime = (seconds) => {
    const milliseconds = Math.floor((seconds % 1) * 1000);
    const hours = Math.floor(seconds / 3600);
    const remainingSecondsAfterHours = seconds % 3600;
    const minutes = Math.floor(remainingSecondsAfterHours / 60) + hours * 60;
    const secondsPart = Math.floor(remainingSecondsAfterHours % 60);
    const paddedMinutes = minutes < 10 ? String(minutes).padStart(2, '0') : minutes;

    return `${paddedMinutes}:${String(secondsPart).padStart(2, '0')}.${String(milliseconds).padStart(3, '0')}`;
};

const transformData = (data, fpsValue) => {
    const transformedData = data ? data.map(item => {
        const updatedItem = {
            ...item,
            Label: item.Label.toUpperCase()
        };
        if (fpsValue) {
            const firstTimestampInSeconds = updatedItem.FirstFrame / fpsValue;
            const lastTimestampInSeconds = updatedItem.LastFrame / fpsValue;
            return {
                ...updatedItem,
                FirstTimestamp: formatTime(firstTimestampInSeconds),
                LastTimestamp: formatTime(lastTimestampInSeconds)
            };
        } else {
          return updatedItem;
        }
    }) : null;
    return transformedData;
}

const sliceData = (data, page, nRows) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    if (page < 1 || nRows < 1) return [];

    const startIndex = (page - 1) * nRows;
    const endIndex = startIndex + nRows;
    return data.slice(startIndex, endIndex);
}

const filterData = (data, selectedLabel) => {
    if (!selectedLabel) {
        return data;
    }
    const lowerCaseLabel = selectedLabel.toLowerCase();
    return data.filter(row => row.Label.toLowerCase() === lowerCaseLabel);
}

export const getTableData = (data, page, nRows, selectedLabel, fpsValue) => {
    const filteredData = filterData(data, selectedLabel);
    const totalRows = filteredData.length;
    // Calculate page IDs for all pages
    const pageIds = {};
    for (let i = 0; i < Math.ceil(totalRows / nRows); i++) {
        const pageData = sliceData(filteredData, i + 1, nRows);
        pageIds[i + 1] = pageData.map(row => row.Id);
    }
    let pageData = sliceData(filteredData, page, nRows);
    const transformedData = transformData(pageData, fpsValue);
    return {
        totalRows: totalRows,
        tableData: transformedData,
        pageIds: pageIds
    };
}

export const getRowsByTime = (data, fps, timestamp) => {
    return data
        .filter(row => {
            const startTime = row.FirstFrame / fps;
            const endTime = row.LastFrame / fps;
            return timestamp >= startTime && timestamp <= endTime;
        })
        .map(row => row.Id);
};

export const findPageWithHighlightIds = (highlightRowIds, pageIds) => {
    // Check for a page that contains all highlightRowIds
    for (const [page, ids] of Object.entries(pageIds)) {
        if (highlightRowIds.every(id => ids.includes(id))) {
            return parseInt(page); 
        }
    }
    // If no page contains all, find the first occurrence of highlightRowIds[0]
    for (const [page, ids] of Object.entries(pageIds)) {
        if (ids.includes(highlightRowIds[0])) {
            return parseInt(page);
        }
    }
    return null;
};