import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { REQUEST_STATUS } from '../../consts/requestStatus';
import DataErrorCard from '../common/DataErrorCard';


function ImageDisplay({ imageData, requestStatus}) {
    if (requestStatus === REQUEST_STATUS.SUCCESS) {
        return (
            <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img 
                    src={imageData} 
                    alt="img" 
                    style={{
                        maxWidth: '700px',
                        width: '100%',
                        height: 'auto' 
                    }}
                />
            </div>
        )
    } else if (requestStatus === REQUEST_STATUS.LOADING) {
        return (
            <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'auto', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton variant="rectangular" height={500} width={600}/>
            </div>
        )
    } else {
        return (<DataErrorCard maxWidth={400} text='An error occurred while loading image. Please try again later or contact the support team.'/>)
    }
}

export default ImageDisplay;