import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useRequestDetections from '../hooks/useRequestDetections'
import VideoDetections from '../components/VideoDetections/VideoDetections';
import InfoTabs from "../components/VideoInfoTabs/InfoTabs";
import { MetadataProvider } from '../contexts/MetadataContext';


function VideoPage() {
    const { videoId } = useParams();
    const [selectedLabel, setSelectedLabel] = useState('');
    const {
        videoInfo,
        videoName,
        labelsStat,
        monitoring,
        data,
        fpsValue,
        videoWithPredictions,
        videoEvaluated,
        dataRequestStatus,
    } = useRequestDetections(videoId);
    const labels = labelsStat
        ? Object.keys(labelsStat[0])
            .filter(label => label !== "Total")
            .sort()
            .map(label => label.toUpperCase())
        : [];
    return (
        <div>
        <MetadataProvider videoId={videoId}>
            <InfoTabs
                videoName={videoName}
                videoInfo={videoInfo}
                labelsStat={labelsStat}
                monitoring={monitoring}
                setSelectedLabel={setSelectedLabel}
                videoWithPredictions={videoWithPredictions}
                videoEvaluated={videoEvaluated}
                dataRequestStatus={dataRequestStatus}
            />
        </MetadataProvider>
        <VideoDetections
            data={data}
            labels={labels}
            selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel}
            fpsValue={fpsValue}
        />
        </div>
    )
}
export default VideoPage;